// src/components/Header.jsx
import React from "react";

const Header = () => {
  return (
    <header className="bg-gray-50 py-6 shadow-md">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="logo">
          <a href="/">
            <img
              src="/logotype.png"
              title="HowToTrade"
              alt="HowToTrade Logo"
              className="max-w-[160px] mr-5"
            />
          </a>
        </div>
        <nav className="flex-grow text-center md:text-right">
          <ul className="list-none flex gap-8 justify-center md:justify-end p-0 mt-5 md:mt-0">
            <li className="inline">
              <a
                href="#methodology"
                className="no-underline text-gray-700 font-bold text-lg tracking-wide transition-colors duration-300 hover:text-[#e72b56]"
              >
                Методология
              </a>
            </li>
            <li className="inline">
              <a
                href="#about"
                className="no-underline text-gray-700 font-bold text-lg tracking-wide transition-colors duration-300 hover:text-[#e72b56]"
              >
                О Нас
              </a>
            </li>
            <li className="inline">
              <a
                href="#contact"
                className="no-underline text-gray-700 font-bold text-lg tracking-wide transition-colors duration-300 hover:text-[#e72b56]"
              >
                Контакты
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
