// src/components/HeroSection.jsx
import React from "react";

const HeroSection = () => {
  return (
    <section className="text-center py-20 bg-white text-gray-900 relative" id="top">
      <div className="absolute inset-0 bg-gradient-to-b from-[#e72b56] to-transparent opacity-20"></div>
      <div className="relative z-10 mx-auto max-w-[1200px]">
        <h1 className="text-4xl font-extrabold mb-6 text-[#e72b56]">
          Лучшие брокеры бинарных опционов 2024
        </h1>
        <p className="text-xl sm:text-2xl mt-4 max-w-3xl mx-auto leading-relaxed text-gray-700">
          Найдите надёжную платформу для прибыльного и эффективного трейдинга. Рейтинг брокеров бинарных опционов от HowToTrade поможет вам сделать правильный выбор для успешной торговли.
        </p>
        <div className="mt-20">
          <a
            href="#broker-ranking"
            className="bg-[#c9254a] text-white py-3 px-8 rounded-full text-lg font-semibold uppercase shadow-md hover:bg-[#b01e40] transition-all duration-300"
          >
            Выбрать брокера
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
