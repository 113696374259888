import React from "react";

const Footer = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://howtotrade.biz/",
    "name": "HowToTrade",
    "logo": "https://howtotrade.biz/logotype.png",
    "email": "info@howtotrade.biz",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Kharkiv",
      "addressCountry": "UA"
    }
  }; 
  return (
    <footer className="bg-[#1c1c1e] text-white mt-10 px-5">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="container mx-auto py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1 */}
          <div>
            <h2 id="about" className="text-2xl font-bold mb-4 text-[#e72b56]">О нас</h2>
            <p className="text-gray-400 leading-relaxed">
              HowToTrade.biz — ваш надежный гид в мире бинарных опционов. Мы специализируемся на объективных обзорах брокеров бинарных опционов и составляем рейтинги лучших, чтобы помочь трейдерам сделать осознанный выбор. Наша цель — предоставить достоверную и актуальную информацию, которая поможет вам ориентироваться в сложном мире финансовых рынков и находить надежных партнеров для успешной торговли.
            </p>
          </div>
          {/* Column 2 */}
          <div>
            <h3 className="text-2xl font-bold mb-4 text-[#e72b56]">Категории</h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#broker-ranking"
                  className="text-gray-400 hover:text-[#e72b56] transition-colors duration-300"
                >
                  Топ бинарных брокеров
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#binary"
                  className="text-gray-400 hover:text-[#e72b56] transition-colors duration-300"
                >
                  Что такое бинарные опционы
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#choose"
                  className="text-gray-400 hover:text-[#e72b56] transition-colors duration-300"
                >
                  Как выбрать брокера бинарных опционов
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#methodology"
                  className="text-gray-400 hover:text-[#e72b56] transition-colors duration-300"
                >
                  Методология
                </a>
              </li>
            </ul>
          </div>
          {/* Column 3 */}
          <div>
            <h3 id="contact" className="text-2xl font-bold mb-4 text-[#e72b56]">Контакты</h3>
            <ul>
              <li className="mb-2">
                Email:{" "}
                <a
                  href="mailto:info@howtotrade.biz"
                  className="text-gray-400 hover:text-[#e72b56] transition-colors duration-300"
                >
                  info@howtotrade.biz
                </a>
              </li>
              <li className="mb-2">Адрес: Харьков, ул. Непокоренных, 7</li>
            </ul>
          </div>
        </div>
        <div className="mt-10 border-t border-gray-700 pt-5 text-center">
          <p className="text-gray-400">
            &copy; 2014-2024 howtotrade.biz | Все права защищены. Копирование материалов разрешено только с указанием активной ссылки на первоисточник.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
