import React, { useState } from 'react';

const FAQSection = () => {
    const faqs = [
        {
          question: "Где лучше торговать бинарными опционами?",
          answer: "Лучше всего выбирать регулируемого брокера с хорошей репутацией, удобной платформой, прозрачными условиями и минимальными комиссиями. Обратите внимание на брокеров с лицензиями от известных финансовых регуляторов."
        },
        {
          question: "Можно ли стабильно зарабатывать на бинарных опционах?",
          answer: "Стабильный заработок на бинарных опционах требует глубоких знаний, практики и четкой стратегии. Многие трейдеры теряют средства из-за высокой волатильности и непредсказуемости рынка, поэтому стабильный доход является сложной задачей."
        },
        {
          question: "На каких бинарных опционах лучше торговать?",
          answer: "Выбор опционов зависит от вашего опыта и стратегии. Популярны классические бинарные опционы с фиксированной прибылью, однако трейдеры также могут использовать долгосрочные или краткосрочные опционы в зависимости от рыночных условий."
        },
        {
          question: "С какой суммы начать торговлю бинарными опционами?",
          answer: "Для начала торговли подойдет минимальная сумма, часто это $10–50. Однако рекомендуется начинать с суммы, которую вы готовы потерять, и постепенно увеличивать депозит по мере получения опыта."
        },
        {
          question: "Почему запретили бинарные опционы?",
          answer: "Бинарные опционы запрещены в некоторых странах из-за высокого риска для трейдеров и потенциальных мошенничеств со стороны недобросовестных брокеров. Регуляторы стремятся защитить инвесторов от потерь, связанных с азартными элементами торговли."
        },
        {
          question: "Зачем нужен брокер бинарных опционов?",
          answer: "Брокер предоставляет доступ к платформе для торговли, выполняет ордера и обеспечивает трейдеров инструментами для анализа рынка. Кроме того, он должен гарантировать безопасность ваших средств и честные условия торговли."
        },
        {
          question: "Как работают брокеры с бинарными опционами?",
          answer: "Брокеры бинарных опционов предоставляют платформу, где трейдеры могут заключать сделки, прогнозируя рост или падение цены актива. Брокеры получают прибыль от спредов, комиссий или от разницы между проигрышами и выигрышами трейдеров."
        },
      ];
      
    
      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        }))
      };  
  return (
    <section className="mt-10 px-5 faq-section">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="mb-5">
        <h2 className="text-4xl font-extrabold text-[#e72b56] mb-10">
          Часто задаваемые вопросы
        </h2>
      </div>
      {/* FAQ Items */}
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-6 faq-item border-b border-gray-300 last:border-b-0">
      <h3
        className={`faq-question text-lg font-bold cursor-pointer py-4 px-6 transition-colors duration-300 shadow-md flex justify-between items-center rounded ${
          isOpen ? 'bg-[#e72b56] text-white' : 'bg-gray-50 hover:bg-gray-100'
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <div className="px-1 w-4">
          <svg
            className={`w-4 h-4 transform transition-transform ${
              isOpen ? 'rotate-180 text-white' : 'rotate-0 text-[#e72b56]'
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 011 0l6.646 6.647 6.647-6.647a.5.5 0 11.708.708l-7 7a.5.5 0 01-.708 0l-7-7a.5.5 0 010-.708z"
            />
          </svg>
        </div>
      </h3>
      {isOpen && (
        <div className="faq-answer px-6 py-4 bg-white shadow-inner rounded">
          <div className="text-gray-700">
            {formatAnswer(answer)}
          </div>
        </div>
      )}
    </div>
  );
};

// Helper function to format the answer text, especially for lists
const formatAnswer = (text) => {
  // Check if the text contains ordered list items
  if (/\d+\./.test(text)) {
    // Split the text by newlines or other delimiters if needed
    const items = text.split(/\d+\./).filter(Boolean).map(item => item.trim());

    return (
      <ol className="list-decimal list-inside">
        {items.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </ol>
    );
  }

  // Otherwise, return the text as is, allowing for basic HTML tags
  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export default FAQSection;
