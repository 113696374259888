// src/components/BrokerCard.jsx
import React from "react";

const BrokerCard = ({
  rank,
  name,
  deposit,
  minBet,
  maxProfit,
  assets,
  platforms,
  regulators,
  rating,
  url,
  logo,
}) => {
  return (
    <div className="flex flex-col md:flex-row bg-gradient-to-r from-white to-gray-100 p-4 rounded-lg shadow-xl border-l-8 border-[#e72b56] items-center gap-2 md:gap-8">
      {/* Broker Header: Rank and Logo */}
      <div className="flex flex-col items-center text-center">
        <div className="mb-4 text-5xl font-extrabold text-[#e72b56]">{rank}</div>
        <img
          src={`/brokers-logo/${logo}`}
          title={name}
          alt={`${name} Logo`}
          className="h-24 w-24"
        />
        <h3 className="mt-2 text-xl font-bold text-gray-800">{name}</h3>
      </div>

      {/* Broker Details in Two Columns */}
      <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 md:mt-0 broker-details">
        <div>
          <div className="mb-2 md:text-center">
            <h4 className="font-bold text-gray-800">Минимальный депозит</h4>
            <p className="text-gray-600 text-xl">{deposit}</p>
          </div>
          <div className="mb-2 md:text-center">
            <h4 className="font-bold text-gray-800">Минимальная ставка</h4>
            <p className="text-gray-600 text-xl">{minBet}</p>
          </div>
          <div className="md:text-center">
            <h4 className="font-bold text-gray-800">Максимальная доходность</h4>
            <p className="text-gray-600 text-xl">{maxProfit}</p>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <h4 className="font-bold text-gray-800">Регуляторы</h4>
            <p className="text-gray-600 text-lg">{regulators}</p>
          </div>
          <div className="mb-2">
            <h4 className="font-bold text-gray-800">Торговые платформы</h4>
            <p className="text-gray-600 text-lg">{platforms}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-800">Торговые активы</h4>
            <p className="text-gray-600 text-lg">{assets}</p>
          </div>
          </div>
        </div>


      {/* Broker Rating */}
      <div className="flex flex-col items-center broker-rating my-4">
        <h4 className="text-xl font-semibold text-gray-800 mb-2">Рейтинг</h4>
        <div className="flex text-yellow-400">
          {[...Array(rating)].map((_, index) => (
            <svg
              key={index}
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <polygon points="10,1 12.6,7.5 19.5,7.5 13.9,11.9 16.5,18.5 10,14.1 3.5,18.5 6.1,11.9 0.5,7.5 7.4,7.5" />
            </svg>
          ))}
        </div>
      </div>

      {/* Call-to-Action Button */}
      <a
        href={url}
        target="_blank"
        rel="sponsored noopener noreferrer"
        className="cta-button bg-[#c9254a] text-white px-8 py-4 rounded-full font-bold text-lg transition-all duration-300 hover:bg-[#b01e40] mb-4 md:mb-0"
      >
        Перейти к брокеру
      </a>
    </div>
  );
};

export default BrokerCard;
